import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    subtitle1: {
      color: "#15A9C5",
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#FF9E15",

    },
    secondary: {
      main: "#B451C2",
    },
    info: {
      main: "#51015C",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#FFC068",
      secondary: "#4FA9BA",
      disabled: "#CCC",
      hint: "#7D7987",
    },
  },
  shape: {
    borderRadius: 20,
  },
})

export default theme
