import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Image from "./image"
import { BorderAllTwoTone } from "@material-ui/icons"

interface IProps {
  service: Service
}

interface Service {
  title: string
  image: string
  description: string
}

const ServiceCard = ({ service, ...props }: IProps) => {
  return (
    <Card>
      <CardContent style={{ textAlign: "center", minHeight: 200, border: "2px solid #E9B8F0", borderRadius: "20px"}}>
        <div style={{ width: 80, padding: 10, display: "block", alignContent: "start", marginLeft: "auto", marginRight: "auto"}}>
          <Image alt={service.title} filename={service.image}/>
        </div>
        <Typography variant="h6" color="inherit">
          {service.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {service.description}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default ServiceCard
